<template>
    <div>
        <h2 class="primary darken-2 white--text font-weight-bold subheading py-2 text-xs-center">REQUEST NEW LOAN</h2>
        <v-divider></v-divider>
        <v-card color="white pa-3" style="" >
            <p class="dark--text pa-0 ma-0 text-xs-center">With Loans you get the best credits.</p>
            <v-layout wrap row>
                <v-flex xs12 md10 offset-md1>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-piggy-bank fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field class="pa-0 mt-3"
                                ref="loan_name" v-model="loanName"
                                label="Enter Loan Name"
                                :rules="[v => !!v || 'Loan Name is required']"
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); loan_name_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-tag fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-select
                                @change="OnChangeLoanType()"
                                class="pt-0 mt-3" color="teal"
                                v-model="loanType" ref="loan_type"
                                :items="loanTypes"
                                :rules="[v => !!v || 'Loan Types is required']"
                                label="Select Loan Types"
                                required
                            ></v-select>
                        </v-flex>
                        <v-flex xs2>
                            <v-btn @click.stop="$store.commit('activateDialog', true); loan_type_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex xs12 md10 offset-md1>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-money-bill fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field class="pa-0 mt-3"
                                ref="loan_target_amount"
                                label="Loan Amount" v-model="loanTargetAmount"
                                :disabled='isAmountDisabled'  :required='isAmountRequired'
                                 color="teal" @change="getLoanInterest()"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); loan_amount_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex xs12 md10 offset-md1>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-money-bill fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field class="pa-0 mt-3"
                                ref="loan_target_interest"
                                label="Loan Interest" v-model="loanInterest"
                                required disabled color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); loan_amount_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-calendar fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field class="pa-0 mt-3"
                                ref="loan_target_date"
                                label="Loan Date" v-model="loanTargetDate"
                                :disabled='isDateDisabled' :required='isDateRequired'
                                type="date"
                                 color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); loan_date_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout  row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-pen-to-square fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-textarea class="pa-0 mt-3"
                                name="input-7-1"
                                auto-grow  v-model="loanDescription"
                                ref="loan_description"
                                label="Loan Description"
                                :rules="[v => !!v || 'Loan Description is required']"
                                background-color="grey lighten-4"  color="teal"
                                rows="2" box
                                row-height="20" 
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); loan_description_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout row wrap>
                        <v-flex xs12 class="pt-3">
                            <h3><i class="teal--text fas fa-wrench"> </i> Select Transactor Operator</h3>                       
                        </v-flex>
                        <v-flex xs6 sm3 md4 offset-sm1 class="pt-2" @click="selectMoMo()">
                            <v-img
                                :src="require('@/assets/mtn-mobile-money-logo.png')"
                                style="cursor: pointer" contain width="100"
                            ></v-img>
                            <v-checkbox v-model="momo_choice" :checked="momo_choice" label="MTN MoMo" hide-details class="ma-1" id="selected_momo">
                            </v-checkbox>
                        </v-flex>
                        <v-flex xs6 sm3 class="pt-2" @click="selectOM()">
                            <v-img
                                :src="require('@/assets/om.png')"
                                style="cursor: pointer" contain width="70"
                            ></v-img>
                            <v-checkbox v-model="om_choice" :checked="om_choice" label="OM" hide-details class="ma-1" id="selected_om">
                            </v-checkbox>
                        </v-flex>
                        <v-flex xs12 sm4 class="pt-4 mt-3" @click="selectPettyCash()">
                            <v-img
                                :src="require('@/assets/logo-2.png')"
                                style="cursor: pointer" contain width="200"
                            ></v-img>
                            <v-checkbox v-model="pettycash_choice" :checked="pettycash_choice" label="Petty Cash" hide-details class="ma-1" id="selected_petty_cash">
                            </v-checkbox>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <div class="text-xs-center">
                <v-btn @click="createNewLoan()" class="white--text elevation-6" type="submit" small round  color="teal" >
                    <span> Submit Request</span>
                </v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                momo_choice: false,
                om_choice: false,
                pettycash_choice: false,

                isAmountDisabled: false,
                isDateDisabled: false,
                isAmountRequired: false,
                isDateRequired: false,
                loan_name_help: false,
                loan_type_help: false,
                loan_operator_help: false,
                loan_amount_help: false,
                loan_date_help: false,
                loan_description_help: false,
                loanName: "",
                loanType: "",
                loanOperator: "",
                loanTargetAmount: "",
                loanInterest: "",
                loanTargetDate: "",
                loanDescription: "",
                loanOperators: [],
                loanTypes: [],
            }
        },
        mounted(){
            // executes these after the page has been mounted
            this.getLoanTypes();
            this.getLoanOperators();
            document.title = "PettyCash | Create New Loan"
            this.$store.commit('setCloseURL', "/savings/loans/active")
        },

        methods:{
            selectMoMo(){
                this.momo_choice = true
                this.om_choice = false
                this.pettycash_choice = false
                this.loanOperator = "MTN Mobile Money"
            },
            selectOM(){
                this. momo_choice = false
                this.om_choice = true
                this.pettycash_choice = false
                this.loanOperator = "Orange Money"
            },
            selectPettyCash(){
                this.momo_choice = false
                this.om_choice = false
                this.pettycash_choice = true
                this.loanOperator = "Top Up Station"
            },

            async getLoanTypes(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/savings/get/loan/types/')
                    .then(response => {
                        this.loanTypes = response.data  // get the data and fill into loans
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })

                this.$store.commit('setIsLoading', false)
            },

            async getLoanInterest(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/savings/get/loan/interest/'+this.loanTargetAmount)
                    .then(response => {
                        this.loanInterest = response.data + " F CFA"  // get the data and fill into loans
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })

                this.$store.commit('setIsLoading', false)
            },

            async getLoanOperators(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/home/get/operators/info/')
                    .then(response => {
                        this.loanOperators = response.data  // get the data and fill into operators
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async createNewLoan(){
                var future = new Date();
                future.setDate(future.getDate() + 30);  // sets the future to 30 days from now
                console.log(future)
                
                if(this.loanName === ""){
                    this.$store.commit('setSnackBarMessage', "Please fill in the loan name")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.loan_name.focus()  // this causes a an auto fucos to the element
                }
                else if(this.loanType === ""){
                    this.$store.commit('setSnackBarMessage', "Please select the loan type")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.loan_type.focus()  // this causes a an auto fucos to the element
                }
                else if(this.loanOperator === ""){
                    this.$store.commit('setSnackBarMessage', "Please select the loan transaction operator")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
                else if ((this.loanType === "MONEY_TARGET" || this.loanType === "MONEY_TIME_TARGET") && Number(this.loanTargetAmount) < 5000){
                    this.$store.commit('setSnackBarMessage', "Please your loan target amount should be from 5000 F CFA and above.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.loan_target_amount.focus()  // this causes a an auto fucos to the element
                }
                else if ((this.loanType === "TIME_TARGET" || this.loanType === "MONEY_TIME_TARGET") && new Date(this.loanTargetDate) < future){
                    this.$store.commit('setSnackBarMessage', "Sorry your loan date must be atleast <b>1 month from now</b>.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.loan_target_date.focus()  // this causes a an auto fucos to the element
                }
                else if(this.loanType === "MONEY_TARGET" && this.loanTargetAmount === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your loan target amount")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.loan_target_amount.focus()  // this causes a an auto fucos to the element
                }
                else if(this.loanType === "TIME_TARGET" && this.loanTargetDate === ""){
                    this.$store.commit('setSnackBarMessage', "Please select your loan target date")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.loan_target_date.focus()  // this causes a an auto fucos to the element
                }
                else if(this.loanType === "MONEY_TIME_TARGET" && this.loanTargetDate === ""){
                    this.$store.commit('setSnackBarMessage', "Please select your loan target date")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.loan_target_date.focus()  // this causes a an auto fucos to the element
                }
                else if(this.loanType === "MONEY_TIME_TARGET" && this.loanTargetAmount === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your loan target amount")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.loan_target_amount.focus()  // this causes a an auto fucos to the element
                }
                else if(this.loanDescription === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your loan description")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.loan_description.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.$store.commit('setIsLoading', true)
                
                    await axios
                        .post('/api/v1/savings/create/new_loan/', {'loanName': this.loanName, 'loanType': this.loanType, 'loanOperator': this.loanOperator, 'loanTargetAmount': this.loanTargetAmount, 'loanTargetDate': this.loanTargetDate, 'loanDescription': this.loanDescription})
                        .then(response => {
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            if(response.data[0].id !== 0){
                                console.log('loans.save:'+response.data[0].id)  // wait after 5000 seconds to redirect user
                                let router = this.$router
                                setTimeout(function(){
                                    router.push('/savings/loans/inactive/')
                                }, 4000)
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                }
            },

            getHelpMessage(){
                if (this.loan_name_help){
                    this.loan_name_help = false
                    this.$store.commit('setDialogTitle', "Loan Name")
                    this.$store.commit('setDialogMessage', "Here you are required to input your loan name. This name is usually descriptive of the objective for your loan.")
                    this.$store.commit('activateDialog', true)
                }
                else if (this.loan_type_help){
                    this.loan_type_help = false
                    this.$store.commit('setDialogTitle', "Loan Type")
                    this.$store.commit('setDialogMessage', "You are required to choose your loan type here. We have 4 types of loans, (Periodic, Money and Money-Time Target). If your target is a certain amount, choose the MONEY TARGET option. If your target is certain date then you choose TIME TARGET. If your target is a certain amount at a particular time, then choose MONEY-TIME TARGET. In the case of you having no idea, you can choose UNDEFINED.")
                    this.$store.commit('activateDialog', true)
                }
                else if (this.loan_operator_help){
                    this.loan_operator_help = false
                    this.$store.commit('setDialogTitle', "Loan Operator")
                    this.$store.commit('setDialogMessage', "You are required to choose the means by which you would get the loan money. We have 3 operator means, (Top Up Stations, MTN Mobile Money, Orange Money). If you choose the topup station, you have to come to the office to collect. Note, sending via Mobile Money/Orange Money will incure charges in your loan.")
                    this.$store.commit('activateDialog', true)
                }
                else if (this.loan_amount_help){
                    this.loan_amount_help = false
                    this.$store.commit('setDialogTitle', "Loan Amount")
                    this.$store.commit('setDialogMessage', "If you have a certain amount saved as your loan objective, you will have to input your targeted amount.")
                    this.$store.commit('activateDialog', true)
                }else if (this.loan_date_help){
                    this.loan_date_help = false
                    this.$store.commit('setDialogTitle', "Loan Date")
                    this.$store.commit('setDialogMessage', "If you have a certain date saved as your loan objective, you will have to input your targeted date.")
                    this.$store.commit('activateDialog', true)
                }else if (this.loan_description_help){
                    this.loan_description_help = false
                    this.$store.commit('setDialogTitle', "Loan Description")
                    this.$store.commit('setDialogMessage', "This entails for you to input the description of why you are setting the objective based savings loan.")
                    this.$store.commit('activateDialog', true)
                }
            },

            OnChangeLoanType(){
                console.log(this.loanType)
                if(this.loanType === "MONEY_TARGET"){
                    this.isAmountDisabled = false
                    this.isDateDisabled = true                    
                    this.isAmountRequired = false
                    this.isDateRequired = true                    
                }
                else if(this.loanType === "TIME_TARGET"){
                    this.isAmountDisabled = true
                    this.isDateDisabled = false                                        
                    this.isAmountRequired = true
                    this.isDateRequired = false                                        
                }
                else if(this.loanType === "MONEY_TIME_TARGET"){
                    this.isAmountDisabled = false
                    this.isDateDisabled = false                                        
                    this.isAmountRequired = false
                    this.isDateRequired = false                                        
                }
                else if(this.loanType === "UNDEFINED TARGET"){
                    this.isAmountDisabled = true
                    this.isDateDisabled = true                                        
                    this.isAmountRequired = true
                    this.isDateRequired = true                                        
                }
            },
        },

    }
</script>
